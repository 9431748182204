<template>
    <v-responsive>
        <v-card tile elevation="0">
            <v-data-table :items="boards" :headers="headers" disable-pagination disable-sort hide-default-footer mobile-breakpoint="" :items-per-page="-1" class="sub-board--table">
                <span slot="item.no" slot-scope="{item}">
                    <v-icon v-if="item.isNotice" color="yellow darken-2">mdi-bell-circle-outline</v-icon>
                    <span v-else>{{ (page - 1) * limit + +boards.filter(noti => !noti.isNotice).indexOf(item) + 1 }}</span>
                </span>
                <router-link text tile slot="item.subject" slot-scope="{item}" :to="`/board/question/${item._id}`">{{ item.subject }}<v-icon v-if="item.isSecret" size="12" class="ml-2">{{ mdiLock }}</v-icon></router-link>
				<span slot="item.writer">관리자</span>
                <span slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD") }}</span>
            </v-data-table>
        </v-card>

		<v-divider/>

        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-4" @input="value => $emit('onPage', value)"/>

		<v-divider class="mt-4"/>

        <v-card-text class="grey lighten-5">
            <v-layout wrap justify-center align-center class="mx-n1">
                <v-flex :xs12="$vuetify.breakpoint.mobile" :shrink="!$vuetify.breakpoint.mobile" class="pa-1"><v-select v-model="filter.searchKey" :items="searchKeyItems" item-text="text" item-value="value" outlined hide-details dense class="white" :style="{ 'maxWidth': $vuetify.breakpoint.mobile ? '100%' : '150px' }" @input="value => filter.searchValue = value ? filter.searchValue : ''"/></v-flex>
				<v-flex :xs12="$vuetify.breakpoint.mobile" :shrink="!$vuetify.breakpoint.mobile" class="pa-1">
					<v-layout class="mx-n1" align-center>
						<v-flex :shrink="!$vuetify.breakpoint.mobile" class="pa-1"><v-text-field v-model="filter.searchValue" outlined hide-details dense class="white" :disabled="!filter.searchKey"/></v-flex>
						<v-flex shrink class="pa-1"><v-btn color="primary" @click="$emit('search', false )"><v-icon small class="mr-2">mdi-magnify</v-icon>검색 </v-btn></v-flex>
					</v-layout>
				</v-flex>
            </v-layout>
        </v-card-text>

        <v-divider />

		<v-layout no-gutters class="ma-1 my-4" :class="$vuetify.breakpoint.mobile ? 'px-4' : ''">
            <v-btn :width="$vuetify.breakpoint.mobile ? '100%' : 'auto'" rounded large color="primary" @click="$emit('write')" class="py-4" :class="$vuetify.breakpoint.mobile ? 'mx-auto' : 'ml-auto'"><v-icon class="mr-3">{{ mdiChatQuestion }}</v-icon>문의하기</v-btn>
        </v-layout>

    </v-responsive>
</template>

<script>
import api from "@/api";
import { mdiChatQuestion, mdiLock } from "@mdi/js";
export default {
    props: {
        boards: { type: Array, default: [] },
        summary: { type: Object },
		filter: { type: Object }
    },
    data(){
        return {
            mdiLock,
			mdiChatQuestion,

            page: this.$attrs.page || 1,
            pageCount: Math.ceil((this.$props?.summary?.totalCount || 0) / this.$attrs.limit) || 0,
            skip: this.$attrs.skip || 0,
            limit: this.$attrs.limit || 10,

            headers: [
                { text: "no", value: "no", align: "center", width: 100 },
                { text: "제목", value: "subject", align: "center" },
				{ text: "작성자", value: "writer.name", align: "center", width: 80 },
                { text: "작성일자", value: "createdAt", align: "center", width: 180 },
            ],

			searchKeyItems: [
				{ text: ":: 검색조건 ::", value: "" },
				{ text: "제목", value: "subject" },
				{ text: "내용", value: "content" },
				{ text: "제목+내용", value: "subject+content" },
				{ text: "작성자", value: "writer.name" },
				{ text: "아이디", value: "writer.username" },
			]
        }
    },
}
</script>
<style>
    .sub-board--table thead {
        display: none;
    }

    @media (max-width:768px) {
        .sub-board--table tr {
            display: flex;
            flex-wrap: wrap;
            padding:15px 0px;
            border-bottom: thin solid rgba(0,0,0,.12);
        }
        /* .sub-board--table tr td:first-child {
            display: none;
        } */
        .sub-board--table tr td:first-child {
            border-bottom:0px !important;
            padding-bottom:5px !important;
        }
        .sub-board--table tr td:nth-child(2) {
            width:100%;
            border-bottom:0px !important;
            text-align: left !important;
            margin-bottom:8px;
        }
        .sub-board--table tr td:nth-child(2) a {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .sub-board--table tr td:nth-child(3), .sub-board--table tr td:nth-child(4) {
            border-bottom:0px !important;
            position:relative;
        }
        .sub-board--table tr td:nth-child(3)::before {
            content: "";
            display: block;
            width:1px;
            height:12px;
            background-color:#999999;
            position:absolute;
            right:0;
            top:50%;
            transform: translateY(-50%);
        }

        .sub-board--table tr td {
            height: auto !important;
        }

    }
    @media (min-width:768px) {
        .sub-board--table thead {
            display: table-header-group;
        }
        .sub-board--table tr {
            display: table-row;
        }
    }
</style>