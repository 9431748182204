var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', [_c('v-card', {
    attrs: {
      "tile": "",
      "elevation": "0"
    }
  }, [_c('v-data-table', {
    staticClass: "sub-board--table",
    attrs: {
      "items": _vm.boards,
      "headers": _vm.headers,
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": "",
      "mobile-breakpoint": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([{
      key: "item.no",
      fn: function (_ref) {
        var item = _ref.item;
        return _c('span', {}, [item.isNotice ? _c('v-icon', {
          attrs: {
            "color": "yellow darken-2"
          }
        }, [_vm._v("mdi-bell-circle-outline")]) : _c('span', [_vm._v(_vm._s((_vm.page - 1) * _vm.limit + +_vm.boards.filter(function (noti) {
          return !noti.isNotice;
        }).indexOf(item) + 1))])], 1);
      }
    }, {
      key: "item.subject",
      fn: function (_ref2) {
        var item = _ref2.item;
        return _c('router-link', {
          attrs: {
            "text": "",
            "tile": "",
            "to": `/board/question/${item._id}`
          }
        }, [_vm._v(_vm._s(item.subject)), item.isSecret ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "size": "12"
          }
        }, [_vm._v(_vm._s(_vm.mdiLock))]) : _vm._e()], 1);
      }
    }, {
      key: "item.createdAt",
      fn: function (_ref3) {
        var item = _ref3.item;
        return _c('span', {}, [_vm._v(_vm._s(_vm.$dayjs(item.createdAt).format("YYYY-MM-DD")))]);
      }
    }])
  }, [_c('span', {
    attrs: {
      "slot": "item.writer"
    },
    slot: "item.writer"
  }, [_vm._v("관리자")])])], 1), _c('v-divider'), _c('v-pagination', {
    staticClass: "mt-4",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "11"
    },
    on: {
      "input": function (value) {
        return _vm.$emit('onPage', value);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('v-divider', {
    staticClass: "mt-4"
  }), _c('v-card-text', {
    staticClass: "grey lighten-5"
  }, [_c('v-layout', {
    staticClass: "mx-n1",
    attrs: {
      "wrap": "",
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('v-flex', {
    staticClass: "pa-1",
    attrs: {
      "xs12": _vm.$vuetify.breakpoint.mobile,
      "shrink": !_vm.$vuetify.breakpoint.mobile
    }
  }, [_c('v-select', {
    staticClass: "white",
    style: {
      'maxWidth': _vm.$vuetify.breakpoint.mobile ? '100%' : '150px'
    },
    attrs: {
      "items": _vm.searchKeyItems,
      "item-text": "text",
      "item-value": "value",
      "outlined": "",
      "hide-details": "",
      "dense": ""
    },
    on: {
      "input": function (value) {
        return _vm.filter.searchValue = value ? _vm.filter.searchValue : '';
      }
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-flex', {
    staticClass: "pa-1",
    attrs: {
      "xs12": _vm.$vuetify.breakpoint.mobile,
      "shrink": !_vm.$vuetify.breakpoint.mobile
    }
  }, [_c('v-layout', {
    staticClass: "mx-n1",
    attrs: {
      "align-center": ""
    }
  }, [_c('v-flex', {
    staticClass: "pa-1",
    attrs: {
      "shrink": !_vm.$vuetify.breakpoint.mobile
    }
  }, [_c('v-text-field', {
    staticClass: "white",
    attrs: {
      "outlined": "",
      "hide-details": "",
      "dense": "",
      "disabled": !_vm.filter.searchKey
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-flex', {
    staticClass: "pa-1",
    attrs: {
      "shrink": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('search', false);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-magnify")]), _vm._v("검색 ")], 1)], 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-layout', {
    staticClass: "ma-1 my-4",
    class: _vm.$vuetify.breakpoint.mobile ? 'px-4' : '',
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-btn', {
    staticClass: "py-4",
    class: _vm.$vuetify.breakpoint.mobile ? 'mx-auto' : 'ml-auto',
    attrs: {
      "width": _vm.$vuetify.breakpoint.mobile ? '100%' : 'auto',
      "rounded": "",
      "large": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('write');
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-3"
  }, [_vm._v(_vm._s(_vm.mdiChatQuestion))]), _vm._v("문의하기")], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }